<template>
    <div class="mister">
        <div class="uk-card uk-card-default uk-card-body ">
            <h1 class="uk-card-title">
                <slot name="pageTitle">{{ translate('sgirot.source.createTitle') }}</slot>
            </h1>
            <div class="page-content">
                <crud-create
                        :formProps="{fieldWrapperClass : 'uk-flex uk-flex-stretch uk-flex-wrap uk-gap-m flex-break-large-start'}"
                        :formConfig="formConfig"
                        itemSource=""
                        subject="source"
                        :redirect="{name: 'source'}"
                ></crud-create>
            </div>
        </div>

    </div>
</template>

<script>

import CrudCreate from '@/client/applications/sgirot/components/crud/Create.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import userFormFields from '@/client/applications/sgirot/forms/simpleUser.js';


export default {

    setup() {
        return useMeta({
                           title: useI18n().t('sgirot.source.createTitle')
                       }).meta;
    },
    props     : {},
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data() {
        let formFields = {
            ...userFormFields,
            name : {
                name          : 'name',
                type          : 'text',
                label         : 'sgirot.source.fields.name',
                placeholder   : 'sgirot.source.fields.namePlaceHolder',
                class         : '',
                id            : '',
                isPrimaryField: true,
                validation    : {
                    'required': {},
                },
                group         : 'group2',
            }
        };
        formFields.password.validation.required = {};
        formFields.email.validation.required = {};
        formFields.email.validation.liveServerSide = {
            message : 'sgirot.user.emailInUseError',
            params:  {
                'url' : 'source/email',
                'data': {

                }
            },
        }

        return {
            entityType: 'source',
            formConfig: {
                fields: formFields,
                groups: {
                    group1: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.source.fields.userFieldsetTitle',
                            icon : 'user',
                            class: 'uk-width-xlarge uk-margin-top',

                        }
                    },
                    group2: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.source.fields.sourceFieldsetTitle',
                            icon : 'info',
                            class: 'uk-width-xlarge uk-margin-top'
                        }
                    }
                },
                buttons: {},
                context: 'edit',
            }
        }
    },
    components: {CrudCreate},
    layout    : 'dashboard',
    methods   : {}
}
</script>
